import { actionsAbstract, mutationsAbstract } from '@/store/abstract'

const state = {
  suppliersDetail: {},
  suppliersCount: null,
  suppliersList: [],
  groupsList: [],
  contactsList: [],
  changeShortcuts: false,
  retrieveLoader: false,
  createLoader: false,
  deleteLoader: false
}

export default {
  namespaced: true,
  state,
  actions: actionsAbstract,
  mutations: mutationsAbstract
}
