import { stateAbstract, actionsAbstract, mutationsAbstract } from '@/store/abstract'
import { auth as api, account as accountApi } from '@/backend/api'
import Vue from 'vue'

const state = {
  me: {},
  LoaderMe: false,
  accountsDetail: {},
  accountsCount: null,
  accountsList: [],
  onboardingsList: [],
  documentsDetail: {},
  resolutionsDetail: {},
  resolutionsList: [],
  membersCount: null,
  membersList: [],
  membersDetail: {},
  groupsCount: null,
  groupsList: [],
  invitationsCount: null,
  invitationsList: [],
  invitationsDetail: {},
  filtersDetail: [],
  filtersCount: null,
  filtersList: [],
  searchCount: null,
  searchList: [],
  tokensList: [],
  keyList: [],
  notificationsList: [],
  notificationsCount: null,
  // webhooks
  conversationsCount: null,
  conversationsList: [],
  eventsCount: null,
  eventsList: [],
  webhooksCount: null,
  webhooksDetail: {},
  webhooksList: [],
  // end webhooks
  // billing
  // paymentsList: [],
  // sourcesList: [],
  // end billing
  ...stateAbstract
}

const actions = {
  BULK_INVITATION: ({ commit }, { persons }) => {
    return new Promise((resolve, reject) => {
      commit('TOGGLE_CREATE_LOADER')
      api.bulkInvitation(persons)
      .then(() => {
        resolve()
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        commit('TOGGLE_CREATE_LOADER')
      })
    })
  },
  SEARCH_LIST: ({ commit }, { resource, query, loader = true }) => {
    return new Promise((resolve, reject) => {
      if (loader) {
        commit('TOGGLE_RETRIEVE_LOADER')
      }
      accountApi.list(resource, query)
      .then(response => {
        commit('SET_SEARCH', response)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
      .finally(() => {
        if (loader) {
          commit('TOGGLE_RETRIEVE_LOADER')
        }
      })
    })
  },

  CLEAR_LIST: ({ commit }) => {
    commit('CLEAR_LIST')
  },
  GET_ME: ({ commit }, { resource, query = {}, loader = true }) => {
    return new Promise((resolve, reject) => {
      if (loader) {
        commit('LOADER_ME')
      }
      accountApi.get(resource, query)
      .then(response => {
        commit('SET_ME', { response: response, resource: resource })
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
      .finally(() => {
        if (loader) {
          commit('LOADER_ME')
        }
      })
    })
  },
  ...actionsAbstract
}
const mutations = {
  SET_SEARCH: (state, response) => {
    Vue.set(state, 'searchList', response.data.hits.hits)
    Vue.set(state, 'searchCount', response.data.hits.total.value)
  },
  CLEAR_LIST: (state) => {
    Vue.set(state, 'searchList', [])
    Vue.set(state, 'searchCount', 0)
  },
  SET_ME: (state, { response }) => {
    let permissionList = []
    if (response?.data) {
      response.data.groups.forEach(({permissions}) => {
        permissionList = [...permissionList, ...permissions]
      })
      permissionList = [...new Set([...response.data.permissions, ...permissionList])]
      state.me = {...response.data, ...{permissions: permissionList}}
    } else state.me = {}
  },
  LOADER_ME: (state) => {
    state.LoaderMe = !state.LoaderMe
  },
  ...mutationsAbstract
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
