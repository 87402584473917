<template>
  <v-row>
    <v-col cols="12" class="px-0 pb-0">
      <v-toolbar color="transparent" flat height="48">
        <v-toolbar-title class="grey-500--text subtitle-1 font-weight-medium">Integraciones</v-toolbar-title>
      </v-toolbar>
    </v-col>
    <v-col cols="12" class="px-0">
      <v-row aling="center" justify="start" class="mx-0">
        <v-col cols="2" v-for="integration in integrations" :key="integration.id">
          <v-card class="py-0 card-apps" @click.stop="dialog=true, selectedIntegration=integration" :ripple="false">
            <v-card-text class="px-2 text-center">
              <v-responsive class="align-center" :aspect-ratio="6/5">
                <img :src="require(`@/assets/appAsset-brand--${integration.img}.svg`)" width="60px">
                <span class="d-block grey-500--text">{{integration.title}}</span>
              </v-responsive>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2">
          <v-card class="py-0 card-apps" :href="zapier.main" target="_blank" hover :ripple="false">
            <v-card-text class="px-2 text-center">
              <v-responsive class="align-center" :aspect-ratio="6/5">
                <v-icon color="grey-500" size="52px">mdi-dots-horizontal</v-icon>
                <span class="d-block mt-2 grey-500--text">Ver más</span>
              </v-responsive>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row aling="center" justify="start" class="mx-0">
        <v-col>
          <v-card class="py-0 card-apps" hover :href="zapier.link" target="_blank" :ripple="false">
            <v-list subheader two-line>
              <v-list-item three-line>
                <v-list-item-avatar tile size="52">
                  <img :src="require(`@/assets/appAsset-brand--Zapier.svg`)" width="60px">
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="pt-1 grey-500--text subtitle-1 font-weight-medium">Zapier</v-list-item-title>
                  <v-list-item-subtitle class="pb-2">Automatización para tus aplicaciones favoritas</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="px-4 py-2">
                  <v-btn :href="zapier.link" target="_blank" text color="blue-500">Unirse a la beta<v-icon color="blue-500" right>mdi-arrow-right</v-icon></v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="dialog" width="600" persistent no-click-animation scrollable overlay-color="grey-500">
        <v-card class="pa-0">
          <v-card-title class="pa-0">
            <v-toolbar flat height="46">
              <v-toolbar-title class="subtitle-1 grey-500--text font-weight-medium">Integra Axteroid con {{selectedIntegration.title}}</v-toolbar-title>
              <v-spacer />
              <v-toolbar-items>
                <v-row align="center" justify="center" class="mr-3">
                  <v-btn class="ml-3" @click="dialog=false" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
                </v-row>
              </v-toolbar-items>
            </v-toolbar>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <span class="grey-500--text subtitle-1">{{selectedIntegration.description}}</span>
              </v-col>
              <v-col cols="12">
                <span class="grey-500--text subtitle-1 font-weight-medium">Ejemplo</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span class="grey-500--text font-weight-medium">Connecta...</span>
                <v-sheet color="#D8E0EA" rounded>
                  <v-row class="mx-0 pa-2">
                    <v-avatar color="white" size="28" rounded>
                      <img v-if="selectedIntegration.img" :src="require(`@/assets/appAsset-brand--axteroid-icon.svg`)" width="24px">
                    </v-avatar>
                    <span class="ml-2 subtitle-1 grey-500--text font-weight-medium">Axteroid</span>
                  </v-row>
                </v-sheet>
              </v-col>
              <v-col class="text-center" cols="1">
                <v-icon class="mt-8" dense size="28px" color="grey-500">mdi-plus</v-icon>
              </v-col>
              <v-col>
                <span class="grey-500--text font-weight-medium">con...</span>
                <v-sheet color="#D8E0EA" rounded>
                  <v-row class="mx-0 pa-2">
                    <v-avatar color="white" size="28" rounded>
                      <img v-if="selectedIntegration.img" :src="require(`@/assets/appAsset-brand--${selectedIntegration.img}.svg`)">
                    </v-avatar>
                    <span class="ml-2 subtitle-1 grey-500--text font-weight-medium">{{selectedIntegration.title}}</span>
                  </v-row>
                </v-sheet>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span class="grey-500--text font-weight-medium">cuando...</span>
                <v-sheet color="#D8E0EA" rounded>
                  <v-row class="mx-0 pa-2">
                    <v-avatar color="white" size="28" rounded>
                      <img v-if="selectedIntegration.img" :src="require(`@/assets/appAsset-brand--axteroid-icon.svg`)" width="24px">
                    </v-avatar>
                    <span class="ml-2 subtitle-1 grey-500--text font-weight-medium">{{selectedIntegration.example.when}}</span>
                  </v-row>
                </v-sheet>
              </v-col>
              <v-col class="text-center" cols="1">
                <v-icon class="mt-8" dense size="28px" color="grey-500">mdi-arrow-right</v-icon>
              </v-col>
              <v-col>
                <span class="grey-500--text font-weight-medium">entonces haz esto!</span>
                <v-sheet color="#D8E0EA" rounded>
                  <v-row class="mx-0 pa-2">
                    <v-avatar color="white" size="28" rounded>
                      <img v-if="selectedIntegration.img" :src="require(`@/assets/appAsset-brand--${selectedIntegration.img}.svg`)" width="24px">
                    </v-avatar>
                    <span class="ml-2 subtitle-1 grey-500--text font-weight-medium">{{selectedIntegration.example.then}}</span>
                  </v-row>
                </v-sheet>
              </v-col>
            </v-row>
            <v-row class="px-3 mt-8" align="center">
              <v-btn :href="zapier.link" target="_blank" color="blue-500">Unirse a la beta <v-icon small right>mdi-arrow-right</v-icon></v-btn>
              <span class="mx-4">o</span>
              <v-btn :href="zapier.main" target="_blank" text color="blue-500">Ir al constructor</v-btn>
            </v-row>
          </v-card-text>
        </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    integrations: [
      {
        id: 'gsheets',
        title: 'Google Sheets',
        description: 'Crea, edita y comparte hojas de cálculo donde quieras que estés con Google Sheets y obtén información automatizada de tus datos.',
        img: 'GoogleSheets',
        example: {
          when: 'Cupón Creado',
          then: 'Crear una Fila'
        }
      },
      {
        id: 'hubspot',
        title: 'HubSpot',
        description: 'HubSpot es una solución de CRM todo en uno para todas las necesidades digitales de marketing y ventas.',
        img: 'HubSpot',
        example: {
          when: 'Cliente Creado',
          then: 'Crear Contacto'
        }
      },
      {
        id: 'slack',
        title: 'Slack',
        description: 'Slack es una plataforma de mensajería instantánea en equipo. Intercambio de documentos y búsqueda de conocimiento para equipos modernos.',
        img: 'Slack',
        example: {
          when: 'Factura Pagada',
          then: 'Enviar Mensaje'
        }
      },
      {
        id: 'twilio',
        title: 'Twilio',
        description: 'Una herramienta sencilla para que los desarrolladores envíen y reciban SMS y llamadas de voz.',
        img: 'Twilio',
        example: {
          when: 'Pago Creado',
          then: 'Enviar SMS'
        }
      },
      {
        id: 'airtable',
        title: 'Airtable',
        description: 'Organiza cualquier cosa con Airtable, una base de datos moderna creada para todos. Airtable es una forma rápida y flexible de crear tablas para organizar desde los clientes potenciales hasta realizar la gestión de inventario.',
        img: 'Airtable',
        example: {
          when: 'Plan Creado',
          then: 'Crear Registro'
        }
      }
    ],
    selectedIntegration: {
      id: '',
      title: '',
      img: '',
      example: {
        when: '',
        then: ''
      }
    },
    zapier: {
      link: 'https://zapier.com/developer/public-invite/127840/e9276ebafdd31047720b76ae27f6de96/',
      main: 'https://zapier.com/webintent/create-zap'
    },
    dialog: false
  })
}
</script>