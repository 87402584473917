const PosRoutes = [
  {
    path: '/branches',
    name: 'BranchListCreate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'PosListCreate',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Tiendas'
    },
    component: () => import('@/modules/pos/branch/views/BranchListCreate')
  },
  {
    path: '/branches/:id',
    name: 'BranchRetrieveUpdate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'PosListCreate',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Información de la tienda'
    },
    props: true,
    component: () => import('@/modules/pos/branch/views/BranchRetrieveUpdate')
  },
  {
    path: '/branches/activity',
    name: 'BranchesActivityList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'PosListCreate',
      requireAuth: true,
      requireAccount: true,
      // use: ['ax'],
      title: 'Actividad de tiendas'
    },
    component: () => import('@/modules/pos/branch/views/BranchesActivityList')
  },
  // {
  //   path: '/pos/groups',
  //   name: 'PosGroupsList',
  //   meta: {
  //     displayNavigationDrawer: true,
  //     displayOrganization: true,
  //     parent: 'PosListCreate',
  //     requireAuth: true,
  //     requireAccount: true,
  //     use: ['fc', 'ax'],
  //     title: 'Grupos - Puntos de venta'
  //   },
  //   component: () => import('@/modules/pos/groups/views/PosGroupsList')
  // },
  // {
  //   path: '/pos/groups/:id',
  //   name: 'PosGroupsRetrieveUpdate',
  //   meta: {
  //     displayNavigationDrawer: true,
  //     displayOrganization: true,
  //     parent: 'PosListCreate',
  //     requireAuth: true,
  //     requireAccount: true,
  //     use: ['fc', 'ax'],
  //     title: 'Información del grupo - punto de venta'
  //   },
  //   component: () => import('@/modules/pos/groups/views/PosGroupsRetrieveUpdate')
  // },
  {
    path: '/pos',
    name: 'PosListCreate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'PosListCreate',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Puntos de venta'
    },
    component: () => import('@/modules/pos/pos/views/PosListCreate')
  },
  {
    path: '/pos/:id',
    name: 'PosRetrieveUpdate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'PosListCreate',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Información del punto de venta'
    },
    component: () => import('@/modules/pos/pos/views/PosRetrieveUpdate')
  },
  {
    path: '/logs',
    name: 'LogsList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'PosListCreate',
      requireAuth: true,
      requireAccount: true,
      use: ['ax'],
      title: 'Logs'
    },
    component: () => import('@/modules/pos/logs/views/LogsList')
  }
]
const list = () => {
  const env = process.env?.VUE_APP_MODE ?? 'ax'
  return PosRoutes?.filter(({meta}) => meta?.use?.some((v) => v === env))
}
export default list()