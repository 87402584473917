import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)
export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        'blue-50': '#e6eefa',
        'blue-100': '#b1c9ef',
        'blue-200': '#8bafe8',
        'blue-500': '#0352cc',
        'blue-600': '#034bba',
        'blue-700': '#023a91',

        'red-100': '#f4b8b8',
        'red-500': '#dd1a1a',

        'green-100': '#dae8c1',
        'green-200': '#c8dda3',
        'green-500': '#88b437',

        'yellow-100': '#f8e4b1',
        'yellow-500': '#e8a803',

        'grey-50': '#D3D3D3',
        'grey-200': '#afb5c4',
        'grey-300': '#6E7A93', // fontDraw
        'grey-500': '#525f7f',
        'grey-700': '#3a435a', // grey-300--text

        'grey-background': '#f4f6fa', // background
        'white-0': '#ffffff',
        'white-1': '#ffffff'
      },
      dark: {
        'blue-50': '#e6eefa',
        'blue-100': '#143363',
        'blue-200': '#8bafe8',
        'blue-500': '#116AEF',
        'blue-600': '#568bdd',
        'blue-700': '#0243AA',

        'red-100': '#5D0E0E',
        'red-500': '#E62A2A',

        'green-100': '#3E511E',
        'green-200': '#c8dda3',
        'green-500': '#96C540',

        'yellow-100': '#654B09',
        'yellow-500': '#D19C13',

        'grey-50': '#222D48',
        'grey-200': '#838CA2',
        'grey-300': '#D9DDE6',
        'grey-500': '#E6E9F0',
        'grey-700': '#C6CFE6',

        'grey-background': '#080C24', // background
        'white-0': '#151E34',
        'white-1': '#080C24'
      }
    }
  }
})
