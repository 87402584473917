import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// MODULES
import analytics from './modules/analytics'
import accounts from './modules/accounts'
import dashboards from './modules/dashboards'
import irs from './modules/irs'
import base from './modules/base'
import global from './modules/global'
import auth from './modules/auth'
import customers from './modules/customers'
import documents from './modules/documents'
import messages from './modules/messages'
import purchases from './modules/purchases'
import suppliers from './modules/suppliers'
import batch from './modules/batch'
import pos from './modules/pos'
import branches from './modules/branches'
import support from './modules/support/support'
import logs from './modules/logs'
import downloadCenter from './modules/download'

Vue.use(Vuex)

// const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  strict: true,
  modules: {
    analytics,
    accounts,
    dashboards,
    irs,
    base,
    global,
    auth,
    customers,
    documents,
    messages,
    purchases,
    suppliers,
    batch,
    pos,
    branches,
    support,
    logs,
    downloadCenter
  },
  plugins: [
    createPersistedState({
      paths: ['auth']
    })
  ]
})
